import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSimpleCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('view-container',{attrs:{"breadcrumbs":"","scroll-fab":""}},[_c(VCard,{attrs:{"flat":""}},[_c('toolbar',{attrs:{"title":_vm.$t('admin.route.roles')}},[_c(VTextField,{staticClass:"d-none d-md-flex mx-1",staticStyle:{"width":"0"},attrs:{"append-icon":"mdi-magnify","label":_vm.$t('general.search'),"single-line":"","hide-details":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c(VDivider,{attrs:{"vertical":""}}),(_vm.$hasRole('SUPER-ADMIN'))?_c(VBtn,{attrs:{"text":"","small":"","color":"green"},on:{"click":function($event){return _vm.$router.push({ name: 'role', params: { id: 'new', back: { name: _vm.$route.name } } })}}},[_c(VIcon,[_vm._v("mdi-shield-lock")]),_c('span',{staticClass:"text--secondary"},[_vm._v(_vm._s(_vm.$t("general.new")))])],1):_vm._e(),(_vm.isGrantedEdit)?_c(VBtn,{attrs:{"text":"","small":"","color":"primary","disabled":!_vm.selected},on:{"click":function($event){return _vm.editRole()}}},[_c(VIcon,[_vm._v("mdi-shield-edit")]),_c('span',[_vm._v(_vm._s(_vm.$t("general.edit")))])],1):_vm._e()],1),_c(VCardText,{staticClass:"overflow-y-auto px-0 py-0",style:(_vm.getViewHeightStyle(84))},[_c(VDataTable,{attrs:{"dense":"","headers":_vm.headers,"items":_vm.getRoles,"item-key":"id","search":_vm.search,"show-select":"","single-select":"","value":_vm.selection,"loading":_vm.loading,"loading-text":_vm.$t('messages.loading')},on:{"item-selected":_vm.selectionChanged,"click:row":_vm.selectionChanged},scopedSlots:_vm._u([{key:"item.data-table-select",fn:function(ref){
var item = ref.item;
var isSelected = ref.isSelected;
var select = ref.select;
return [_c(VSimpleCheckbox,{attrs:{"on-icon":"mdi-checkbox-marked-circle","off-icon":"mdi-power-off","value":isSelected,"color":"primary","light":"","ripple":false},on:{"input":function($event){return select($event)}}})]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }